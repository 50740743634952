/**
 * FeatureDevlopersWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { API_URL, TALENT, FEATURE } from 'containers/App/constants';
import request from 'utils/request';
import { VALIDATION, DEFAULT_LIMIT } from 'utils/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DeveloperWidgetBlock from './DeveloperWidgetBlock';
import { DeveloperJobWidgetSlider } from './styles';

import { Row, Col } from 'reactstrap';
import 'rc-pagination/assets/index.css';

import LongTalentCard from 'components/LongTalentCard';
import Pagination from 'rc-pagination';
import { PrivateGrid } from 'components';
import Content from 'components/Content';
import { textItemRender } from 'containers/TalentListingPage/utils';
import { localeInfo } from 'containers/TalentListingPage/constants';
import StorageService from 'utils/StorageService';

export const sortUrl = (sort) => {
  let url = '';
  let val = '';
  switch (sort) {
    case 'recentlowToHigh':
      val = { _id: 1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    case 'recenthighToLow':
      val = { _id: -1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    case 'lowToHigh':
      val = { experienceOrder: 1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    case 'highToLow':
      val = { experienceOrder: -1 };
      url = `sort=${encodeURIComponent(JSON.stringify(val))}`;
      break;
    default:
  }
  return url;
};

export class FeatureDevlopersWidget extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      developerList: [],
      params: queryString.parse(location.search),
      paginationData: {},
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.appyTransperantBg();
    this.loadTalentDetails();
    this.setWindowWidth();
  }

  appyTransperantBg = () => {
    document.body.className += ' bg-transparent';
    document.getElementById('app').className += ' bg-transparent';
  };

  loadTalentDetails = (pageNum = 1) => {
    StorageService.set('featuredTalentsPageNumber', JSON.stringify(pageNum));
    this.setState({ pageNum });
    const data = { method: 'GET' };
    const filterParam = [];
    const { params } = this.state;
    const { role = null, limit = 5, skills = null, yearsOfExperience = null, teamPreference = null, total = 27 } = params;
    const { sort = null } = params;
    if (role) {
      filterParam.push(`role=${role}`);
    }
    if (skills) {
      filterParam.push(`skills=${skills}`);
    }
    if (yearsOfExperience) {
      filterParam.push(`yearsOfExperience=${yearsOfExperience}`);
    }
    if (teamPreference) {
      filterParam.push(`teamPreference=${teamPreference}`);
    }
    if (sort) {
      filterParam.push(sortUrl(sort));
    }

    if (total) {
      filterParam.push(total);
    }

    const requestURL = `${API_URL}${TALENT}${FEATURE}?${filterParam.join('&')}&limit=${limit}&page=${pageNum}&paginate=${pageNum}`;
    request(requestURL, data)
      .then(this.setJobPostDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setJobPostDetails = (response) => {
    if (get(response, 'status')) {
      const { data } = response;
      this.setState({
        developerList: data.docs,
        paginationData: response.data,
      });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  setWindowWidth = () => {
    window.addEventListener('resize', () => {
      this.setState({ windowWidth: window.innerWidth });
    });
  };

  render() {
    const { developerList, paginationData, params, windowWidth } = this.state;
    const { limit = 5 } = params;

    return (
      <Content>
        <PrivateGrid>
          <Row>
            <React.Fragment>
              {(developerList || []).map((talent) => (
                <Col xs={12} sm={6} lg={4} className="d-flex flex-column" key={talent._id}>
                  <LongTalentCard
                    data={talent}
                    clientDetails={{
                      subscribed: true,
                    }}
                    SubscriptionModalRef={null}
                    isFeatured
                    hireButtonText="Schedule Interview"
                    showInterviewTalent={false}
                    sliceBy={windowWidth <= 768 ? 2 : 3}
                    showHireButton={false}
                  />
                </Col>
              ))}
            </React.Fragment>
          </Row>
          {paginationData.totalDocs > limit ? (
            <Pagination
              total={paginationData.totalDocs}
              className="ant-pagination mt-0"
              current={paginationData.page}
              pageSize={limit}
              onChange={this.loadTalentDetails}
              itemRender={textItemRender}
              locale={localeInfo}
            />
          ) : (
            ''
          )}
        </PrivateGrid>
      </Content>
    );

    // return (
    //   <DeveloperJobWidgetSlider {...settings}>
    //     {(developerList || []).map((developer) => (
    //       <DeveloperWidgetBlock key={developer} developer={developer} />
    //     ))}
    //   </DeveloperJobWidgetSlider>
    // );
  }
}

FeatureDevlopersWidget.defaultProps = {
  location: '',
};

FeatureDevlopersWidget.propTypes = {
  location: PropTypes.object,
};

export default FeatureDevlopersWidget;
