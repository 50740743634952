import {
  CLIENT_DASHBOARD,
  HostCompetitionBannerBackground,
  TrophyIcon,
  UpgradePlanIcon,
  UpgradePriceBannerBackground,
} from 'containers/App/constants';

export const successPath = `${CLIENT_DASHBOARD}?success=true`;
export const cancelPath = `${CLIENT_DASHBOARD}?success=false`;

export const bannerCardData = {
  hostCompetition: {
    background: HostCompetitionBannerBackground,
    headerText: 'Create and host custom competitions',
    description:
      'Notchup connects you with talents and teams that are eager to solve real-world business challenges. \nStage a bounty now!',
    icon: TrophyIcon,
    iconWidth: 76.43,
    iconHeight: 92.46,
    testId: 'host-icon',
  },
  upgradePlan: {
    background: UpgradePriceBannerBackground,
    headerText: 'Supercharge your hiring process',
    description: "Accelerate your company's growth by upgrading to a Plus plan and unlock more tools for faster hiring",
    icon: UpgradePlanIcon,
    testId: 'upgrade-icon',
  },
};
