import React from 'react';
import { CardSkeleton, TeamCardSkeleton } from 'components/SkeletonLoader';
import { isNull } from 'lodash';
import CustomProgressBar from './CustomProgressBar';

export const sortUrl = (sort) => {
  let val = '';
  switch (sort) {
    case 'recommend':
      val = 'recommend';
      break;
    case 'lastest':
      val = '-_id';
      break;
    case 'oldest':
      val = '_id';
      break;
    case 'seniority':
      val = 'seniority';
      break;
    case 'aToZ':
      val = 'name';
      break;
    case 'zToA':
      val = '-name';
      break;
    case 'lowToHigh':
      val = 'experienceOrder';
      break;
    case 'highToLow':
      val = '-experienceOrder';
      break;
    /*
      Listing page Sort
    */
    case 'talentHighToLow':
      val = '-id';
      break;
    case 'talentAToZ':
      val = 'name';
      break;
    case 'talentZToA':
      val = '-name';
      break;
    default:
  }
  const url = val ? `&sort=${encodeURIComponent(val)}` : '';
  return url;
};

export const defaultSortUrl = (sort) => {
  let url = '';
  let val = '';
  switch (sort) {
    case 'aToZ_Name':
      val = 'name';
      break;
    case 'zToA_Name':
      val = '-name';
      break;
    case 'lowToHigh':
      val = '_id';
      break;
    case 'highToLow':
      val = '-_id';
      break;
    case 'lowToHigh_Exp':
      val = 'experienceOrder';
      break;
    case 'highToLow_Exp':
      val = '-experienceOrder';
      break;
    case 'recommend':
      val = 'recommend';
      break;
    case 'largeToSmall_teamSize':
      val = '-team-size';
      break;
    case 'smallToLarge_teamSize':
      val = 'team-size';
      break;
    default:
  }
  url = val ? `&sort=${encodeURIComponent(val)}` : '';
  return url;
};

export const columnSortUrl = (sort) => {
  const { column, sortDirection } = sort;
  let url = '';
  const sortValue = sortDirection === 'desc' ? '-' : '';
  url = `&sort=${sortValue}${column}`;
  if (column === 'referral' || column === 'referrer') {
    url = `&sort=${sortValue}${column}.firstName,${column}.lastName`;
  }
  return url;
};

export const skillsUrl = (skillsArray) => {
  const newSkillsArray = skillsArray.map((value) => {
    if (value instanceof Object) {
      return value.value;
    }
    return value;
  });
  let skills = '';
  skills += skillsArray.length > 0 ? newSkillsArray : '';
  return skills ? `&skills=${encodeURIComponent(skills)}` : '';
};

export const searchUrl = (search) => (search ? `&q=${search}` : '');

export const textItemRender = (current, type, element) => {
  if (type === 'prev') {
    return 'Previous';
  }
  if (type === 'next') {
    return 'Next';
  }
  return element;
};

export const loadingTalents = () => (
  <React.Fragment>
    <CardSkeleton cardCount={4} />
    <CardSkeleton cardCount={4} />
    <CardSkeleton cardCount={4} />
  </React.Fragment>
);

export const loadingTeams = () => (
  <React.Fragment>
    <TeamCardSkeleton cardCount={1} />
    <TeamCardSkeleton cardCount={1} />
    <TeamCardSkeleton cardCount={1} />
  </React.Fragment>
);

export const removeBlankNames = (data) => (data || []).filter((item) => !isNull(item.name));

export const scoreBreakdownList = [
  { name: 'Technical Skills', key: 'technicalSkills' },
  { name: 'Soft Skills', key: 'softSkills' },
  { name: 'Budget', key: 'budget' },
  { name: 'Industry Experience', key: 'industryExperience' },
  { name: 'Miscellaneous', key: 'misc' },
  { name: 'DISC Profile', key: 'discProfile', boolean: true },
  { name: 'Work Style', key: 'teamWorkingStyle', boolean: true },
  { name: 'Seniority', key: 'seniority', boolean: true },
  { name: 'Location Preference', key: 'locationPreference', boolean: true },
];

export const colorMapper = {
  warning: '255,193,7',
  success: '25, 135, 84',
  danger: '220, 53, 69',
  primary: '13, 110, 253',
};

export const getColorVariant = (value) => {
  if (value >= 70 && value <= 100) {
    return 'success'; // Green
  } else if (value >= 40 && value < 70) {
    return 'warning'; // Amber
  } else if (value >= 0 && value < 40) {
    return 'danger'; // Red
  }
  return 'primary'; // Default color if value is outside the range
};

export const getScoreBreakdownContent = (scoreData) => {
  const getColorForValue = (key) => {
    const value = scoreData[key];
    return value !== undefined ? getColorVariant(value) : 'warning';
  };

  const getBooleanColor = (key) => {
    const value = scoreData[key];
    return value === true ? 'success' : value === false ? 'danger' : 'warning';
  };
  return scoreBreakdownList
    .filter((scoreItem) => {
      const itemKey = scoreItem.key;
      const isBoolean = !!scoreItem.boolean;
      return isBoolean || Number(scoreData[itemKey]) !== 0;
    })
    .map((scoreItem) => {
      const itemKey = scoreItem.key;
      const isBoolean = !!scoreItem.boolean;

      const displayValue = isBoolean ? (scoreData[itemKey] ? 'Yes' : 'No') : `${scoreData[itemKey]}%`;

      const colorStyle = {
        color: `rgb(${colorMapper[isBoolean ? getBooleanColor(itemKey) : getColorForValue(itemKey)]})`,
      };
      return (
        <div key={itemKey} className="mb-3">
          <div className="d-flex justify-content-between mb-1">
            <div>{scoreItem.name} </div>
            <div className="ms-2">
              <span style={colorStyle}>{displayValue}</span>
            </div>
          </div>
          {!isBoolean && <CustomProgressBar value={scoreData[itemKey]} />}
        </div>
      );
    });
};
