import get from 'lodash/get';
import StorageService from 'utils/StorageService';
import { timeXZone } from 'containers/App/constants';
import { getUserTimeZone } from 'utils/Helper';

export const processData = (data) => {
  const output = [];
  data.forEach((item) => {
    const option = {
      label: item.name,
      value: get(item, '_id'),
    };
    output.push(option);
  });
  return output;
};

export const processDataWithCountry = (data) => {
  const output = [];
  data.forEach((item) => {
    const option = {
      label: `${item.name}, ${get(item, 'country')}`,
      value: get(item, '_id'),
    };
    output.push(option);
  });
  return output;
};

export const getStepperClass = (currentStep, index) => {
  let output = '';
  if (currentStep === index + 1) {
    output = 'active';
  }
  if (index + 1 < currentStep) {
    output = 'completed';
  }
  return output;
};

export const getClientJobBriefTimeZone = () => {
  const clientTimeZone = StorageService.get('ClientTimeZone');
  let timeZoneItem = getUserTimeZone();
  if (clientTimeZone) {
    timeZoneItem = timeXZone.find((t) => t.name === clientTimeZone);
  }
  if (timeZoneItem) {
    timeZoneItem = { label: `(${timeZoneItem.offset}) ${timeZoneItem.name}`, value: timeZoneItem.name };
  }
  return timeZoneItem;
};
