import React from 'react';
import PropTypes from 'prop-types';
import { P, H4 } from 'components';
import { getTimezoneOffest } from 'containers/MyProfilePage/components/utils';
import { Link } from 'react-router-dom';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import SVG from 'react-inlinesvg';
import ToolTip from 'components/ToolTip';
import { logoPlaceholder, countryTimeXZone, professionIcon, industryIcon, starTickIcon, TALENT } from 'containers/App/constants';
import get from 'lodash/get';
import containerMessages from 'containers/MyProfilePage/messages';
import messages from './messages';
import { DeveloperWidgetCard, DevTypeRating, SkillsBlock, ExpBlock, IndustryBlock, HRLine } from './styles';

const DeveloperWidgetBlock = (props) => {
  const { developer } = props;
  const timeZoneStr = `${developer.city}, ${developer.country} | ${getTimezoneOffest(countryTimeXZone, 'name', developer.timeZone)}`;
  return (
    <DeveloperWidgetCard>
      <div className="card-widget d-flex justify-content-center">
        <div className="inner-card">
          <Link className="text-decoration-none" to={`${TALENT}/${get(developer, 'profileId', '')}`} target="_blank">
            <div className="logo-icon" key={get(developer, '_id')}>
              {developer.profilePicture ? <img src={developer.profilePicture} alt={developer.firstName} /> : <SVG src={logoPlaceholder} />}
            </div>
            <div className="inner-card-block">
              <H4>
                {get(developer, 'firstName', '-')}
                {get(developer, 'verifiedProfile', false) && (
                  <ToolTip
                    wrapperClass="d-inline-flex ms-1 align-text-top"
                    type="other"
                    otherIcon={starTickIcon}
                    placement="right"
                    content={
                      <ul className="m-0 ps-2">
                        <li className="text-start">{containerMessages.interviewedForSkillsAndExpertise.defaultMessage}</li>
                        <li className="text-start">{containerMessages.checkedForEducationAndExperiences.defaultMessage}</li>
                        <li className="text-start">{containerMessages.verifiedPhotoIDandAddress.defaultMessage}</li>
                      </ul>
                    }
                    tooltipId="verifiedProfileToltip"
                  />
                )}
                <span className="mr-2">{messages.hireNow.defaultMessage}</span>
              </H4>
              {developer.timeZone && (
                <P className="p16 m-0 time-zone" title={timeZoneStr}>
                  <HTMLEllipsis unsafeHTML={timeZoneStr} maxLine="1" ellipsis="..." basedOn="letters" />
                </P>
              )}
              <HRLine />
              <DevTypeRating>
                <span className="dev-type">
                  <span className="label mb-2">{messages.distinguished.defaultMessage}</span>
                  <span>
                    <b>{developer.primaryRole}</b>
                  </span>
                </span>
                <span className="rating" />
              </DevTypeRating>
              <HRLine />
              <div className="skillsExp">
                {developer.skills.length > 0 && (
                  <SkillsBlock>
                    <span className="icon">
                      <SVG width={16} height={16} src={professionIcon} />
                    </span>
                    <ul>
                      {get(developer, 'skills', []).length > 0
                        ? get(developer, 'skills', [])
                            .slice(0, 2)
                            .map((skill) => <li key={get(skill, '_id')}>{skill.name}</li>)
                        : null}
                      {get(developer, 'skills', []).length > 2 ? <li>+{get(developer, 'skills', []).length - 2} Skills</li> : ''}
                    </ul>
                  </SkillsBlock>
                )}
                {developer.industries && (
                  <IndustryBlock>
                    <P className="mb-0  d-flex align-items-start p14">
                      <span className="icon">
                        <SVG width={16} height={16} src={industryIcon} />
                      </span>
                      <HTMLEllipsis
                        unsafeHTML={developer.industries
                          .reduce((acc, industry) => {
                            acc.push(industry);
                            return acc;
                          }, [])
                          .join(', ')}
                        maxLine="2"
                        ellipsis="..."
                        basedOn="letters"
                      />
                    </P>
                  </IndustryBlock>
                )}
              </div>

              {developer.workExperience.length > 0 && (
                <>
                  <HRLine />
                  <ExpBlock>
                    <P className="mb-2 p16">{messages.formerlyAt.defaultMessage}</P>
                    {developer.workExperience.length > 0 && (
                      <ul>
                        {developer.workExperience.map((work) => (
                          <React.Fragment>
                            <li key={get(work, '_id')}>{work.employer}</li>
                          </React.Fragment>
                        ))}
                      </ul>
                    )}
                  </ExpBlock>
                </>
              )}
            </div>
          </Link>
        </div>
      </div>
    </DeveloperWidgetCard>
  );
};

export default DeveloperWidgetBlock;
DeveloperWidgetBlock.defaultProps = {
  developer: {},
};

DeveloperWidgetBlock.propTypes = {
  developer: PropTypes.object,
};
