import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink, Nav } from 'reactstrap';
import SVG from 'react-inlinesvg';
import get from 'lodash/get';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Emitter from 'utils/emitter';
import { userExists, getUserRegisterType } from 'utils/Helper';
import { defaultProps, propTypes } from 'containers/proptypes';
import { Badge } from 'components';
import Logo from 'components/Brand';
import { communityIcon, COMMUNITY_URL, TALENT, closeIcon } from 'containers/App/constants';
import { talentNavPages, agencyNavPages } from './constants';
import { DotSidebar, FixedSidebar } from './styles';
import messages from './messages';
import 'react-perfect-scrollbar/dist/css/styles.css';
import StorageService from 'utils/StorageService';
import { setOpenMenu } from 'containers/App/reducer';
import { connect } from 'react-redux';

export class TalentSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props?.openMenu,
      isSignIn: userExists(),
      roleType: getUserRegisterType(),
      badgeConfiguration: {},
    };
  }

  menToggle = () => {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        const { isOpen } = this.state;
        Emitter.emit(Emitter.EVENTS.HAMBURGER_TOGGLE, isOpen);
      },
    );
  };

  componentDidMount() {
    Emitter.on('badgeConfigurationUpdated', (badgeConfigurationUpdated) => {
      this.setState({ badgeConfiguration: badgeConfigurationUpdated });
    });
    Emitter.on(Emitter.EVENTS.HAMBURGER_TOGGLE, (isOpen) => {
      this.setState({ isOpen });
    });
  }

  componentWillUnmount() {
    Emitter.off('badgeConfigurationUpdated');
    Emitter.off(Emitter.EVENTS.HAMBURGER_TOGGLE);
  }

  renderAgencySideBar = (pathName) => (
    <>
      {agencyNavPages.map((navItem) => (
        <NavItem key={navItem.title}>
          <NavLink
            to={navItem.pathName}
            className={navItem.paths.some((i) => i === pathName) ? 'active' : ''}
            tag={Link}
            title={navItem.title}
          >
            <span className="icon">
              <SVG src={navItem.icon} />
              {this.renderNewBadge(navItem)}
            </span>
            <span className="menu-option">
              {navItem.title}
              {this.renderNewBadge(navItem)}
            </span>
          </NavLink>
        </NavItem>
      ))}
    </>
  );

  renderNewBadge = (navItem) => {
    const { badgeConfiguration } = this.state;
    return (
      <>
        {navItem.isBadgeConfig ? (
          <>
            {badgeConfiguration[navItem.badgeKey] && (
              <Badge data-test-id="badge-sidebar" className="primary-dark new-badge font-0">
                {messages.newBadgeText.defaultMessage}
              </Badge>
            )}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  renderDot = (navItem) => {
    const { badgeConfiguration } = this.state;
    return (
      <>
        {navItem.isBadgeConfig ? (
          <>{badgeConfiguration[navItem.badgeKey] && <DotSidebar data-test-id={'dot-sidebar'} className="dot-sidebar" />}</>
        ) : (
          ''
        )}
      </>
    );
  };

  renderTalentSideBar = (pathName, props) => (
    <div data-testid="renderTalentSideBar">
      {talentNavPages({
        registerType: StorageService.get('registerType'),
        apiSignupStep: StorageService.get('apiSignupStep'),
      })
        .filter((item) => item?.hidden === false)
        .map((navItem) => (
          <NavItem key={navItem.title} className="position-relative">
            <NavLink
              onClick={() => props.dispatch(setOpenMenu(false))}
              to={navItem.pathName}
              className={navItem.paths.some((i) => i === pathName) ? 'active' : ''}
              tag={Link}
              title={navItem.title}
            >
              <span className={`${navItem.key === 'referrals' ? 'referral-icon' : ''} icon`}>
                <SVG src={navItem.icon} />
                {this.renderNewBadge(navItem)}
              </span>
              <span className="menu-option">
                {navItem.title}
                {this.renderDot(navItem)}
                {/* {this.renderNewBadge(navItem)} */}
              </span>
            </NavLink>
          </NavItem>
        ))}

      <NavItem>
        <NavLink
          target="_blank"
          href={COMMUNITY_URL}
          className={`${pathName === `${TALENT}/community` || pathName === `${TALENT}/community/` ? 'active' : ''}`}
          title="Community"
        >
          <span className="icon">
            <SVG src={communityIcon} />
          </span>
          <span className="menu-option">{messages.CommunityMenu.defaultMessage}</span>
        </NavLink>
      </NavItem>
    </div>
  );

  render() {
    const { location, openMenu } = this.props;
    const { roleType, isSignIn, isOpen } = this.state;
    const pathName = get(location, 'pathname');

    return (
      <>
        <FixedSidebar className={isOpen ? 'sidebar-open' : 'sidebar-close'} data-testid="TalentSidebar_FixedSidebar">
          <PerfectScrollbar>
            <div className="d-none d-md-block">
              <Logo />
            </div>
            <div className="d-flex justify-content-end d-md-none pe-4">
              {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */}
              {/* eslint-disable jsx-a11y/click-events-have-key-events  */}
              <img src={closeIcon} onClick={this.menToggle} width="12.5" height="12.5" alt="close" />
            </div>
            <Nav className="side-bar-data">
              {isSignIn && roleType !== 'agency' && this.renderTalentSideBar(pathName, this.props)}
              {isSignIn && roleType === 'agency' && this.renderAgencySideBar(pathName, this.props)}
            </Nav>
          </PerfectScrollbar>
        </FixedSidebar>
      </>
    );
  }
}

TalentSidebar.defaultProps = defaultProps;
TalentSidebar.propTypes = propTypes;

export default connect(null, null)(TalentSidebar);
