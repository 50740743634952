/* eslint-disable no-underscore-dangle */
/*
 * TeamCard
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import get from 'lodash/get';
import { certificationsIcon, diamondIcon, industryIcon, teamIcon, teamPreferenceArray } from 'containers/App/constants';
import { Badge, H5, P } from 'components';
import UnControlledTooltip from 'components/UnControlledTooltip';
import TalentCardHeader from './TalentCardHeader';
import TalentCardDescription from './TalentCardDescription';

import { TalentCardLink, TalentCardBlock } from './styles';
import { PreferencesList } from 'containers/MyProfilePage/styles';
import SVG from 'react-inlinesvg';
import { getArrayLabels } from 'containers/MyProfilePage/components/utils';

function LongTalentCard(props) {
  const {
    data = {},
    columnClass,
    showInterviewTalent = true,
    clientDetails,
    SubscriptionModalRef,
    isFeatured,
    hireButtonText = 'Schedule Interview',
    index,
    setSaveToAList,
    sliceBy = 14,
  } = props;

  const naviateToDetails = (profileId) => {
    const profileUrl = `/talent/${profileId}`;
    window.open(profileUrl, '_blank', 'noopener,noreferrer');
  };

  const isSubscribed = clientDetails?.subscribed;

  return (
    <Col xs={12} className={`${columnClass}`}>
      <TalentCardLink
        onClick={() => {
          if (!isSubscribed) {
            return SubscriptionModalRef.current.handleSubscriptionPlanOpenModal();
          }

          naviateToDetails(get(data, 'profileId'));
        }}
      >
        <TalentCardBlock className="cursor-pointer rounded-bordered-card">
          <div className="d-flex flex-column flex-md-row">
            <TalentCardHeader
              data={data}
              showInterviewTalent={showInterviewTalent}
              setSaveToAList={setSaveToAList}
              isSubscribed={isSubscribed}
              SubscriptionModalRef={SubscriptionModalRef}
            />
            <div className="ms-md-3 w-100">
              <TalentCardDescription
                data={data}
                showInterviewTalent={showInterviewTalent}
                isSubscribed={isSubscribed}
                SubscriptionModalRef={SubscriptionModalRef}
                hireButtonText={hireButtonText}
                setSaveToAList={setSaveToAList}
              />
            </div>
          </div>
          <hr className="my-2" />
          <div className="d-flex align-items.center my-3">
            <P className="p14 m-0 p-0 me-2" opacityVal="0.5" lineHeight="21px">
              {get(data, 'yearsOfExperience')}
            </P>
            <H5 className="m-0 p-0">{get(data, 'primaryRole')}</H5>
          </div>
          <hr className="my-2" />

          {get(data, 'skills') && get(data, 'skills', []).length >= 1 ? (
            <div className="d-flex flex-wrap flex-0 mt-3">
              {get(data, 'skills', [])
                .slice(0, sliceBy)
                .map((subItem) => (
                  <Badge key={subItem.name} className="primary me-2 mb-3">
                    {subItem.name.length > 20 ? `${subItem.name.slice(0, 20)}...` : subItem.name}
                  </Badge>
                ))}
              {get(data, 'skills', []).length > 14 && (
                <>
                  <Badge id={`TooltipTalentCardSkills${data._id}`} className="primary me-2 mb-3">
                    +{get(data, 'skills', []).length - 14} Skills
                  </Badge>
                  <UnControlledTooltip
                    targetId={`TooltipTalentCardSkills${data._id}`}
                    content={get(data, 'skills', [])
                      .slice(14)
                      .map((skill) => skill.name)
                      .join(', ')}
                    placement="top"
                    bgColor="bg-main"
                  />
                </>
              )}
            </div>
          ) : (
            ''
          )}

          <hr className="mb-2 mt-0" />

          <PreferencesList className="d-flex flex-column flex-1 my-3">
            {get(data, 'certificateDetails') && get(data, 'certificateDetails', []).length >= 1 ? (
              <li>
                <SVG src={certificationsIcon} />
                <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                  {get(data, 'certificateDetails', [])
                    .map((obj) => obj.name)
                    .map((i) => i)
                    .join(', ')}
                </P>
              </li>
            ) : (
              ''
            )}
            {get(data, 'industries') && get(data, 'industries', []).length >= 1 ? (
              <li>
                <SVG src={industryIcon} />
                <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                  {get(data, 'industries', [])
                    .map((i) => i)
                    .join(', ')}
                </P>
              </li>
            ) : (
              ''
            )}
            {get(data, 'companyCultures') && get(data, 'companyCultures', []).length >= 1 ? (
              <li>
                <SVG src={diamondIcon} />
                <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                  {get(data, 'companyCultures', [])
                    .map((i) => i)
                    .join(', ')}
                </P>
              </li>
            ) : (
              ''
            )}
            {get(data, 'teamPreference') && get(data, 'teamPreference', []).length >= 1 ? (
              <li>
                <SVG src={teamIcon} />
                <P className="p16 mb-0 text-truncate d-block" opacityVal="0.5">
                  {getArrayLabels(get(data, 'teamPreference', []), [
                    {
                      id: 1,
                      label: 'Individuals',
                      isChecked: false,
                      value: 'individuals',
                    },
                    ...teamPreferenceArray,
                  ])
                    .map((i) => i)
                    .join(', ')}
                </P>
              </li>
            ) : (
              ''
            )}
          </PreferencesList>

          <hr className="my-2" />
          <div className="d-flex align-items-center my-3">
            <P className="p14 m-0 p-0 me-2" opacityVal="0.5" lineHeight="21px">
              Formerly at
            </P>
            <P className="p16 m-0 p-0">{get(data, 'formerEmployer', [])}</P>
          </div>
        </TalentCardBlock>
      </TalentCardLink>
    </Col>
  );
}

LongTalentCard.defaultProps = {
  data: {},
  columnClass: '',
  showInterviewTalent: true,
};

LongTalentCard.propTypes = {
  data: PropTypes.object,
  columnClass: PropTypes.string,
  showInterviewTalent: PropTypes.bool,
};

export default LongTalentCard;
