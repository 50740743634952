import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import Emitter from 'utils/emitter';
import history from 'utils/history';
import { LinkButtonMod, ProgressMod } from 'components';
import StorageService from 'utils/StorageService';
import { rightAngleIcon, circleTickIcon } from 'containers/App/constants';
import {
  talentOnboardingSteps,
  agencyTalentOnboardingSteps,
  clientOnboardingSteps,
  recruiterOnboardingSteps,
  ambassadorOnboardingSteps,
  agencyOnboardingSteps,
} from 'containers/Auth/constants';
import { handleBackButton } from 'containers/Auth/utils';
import { TabSectionBlock } from './styles';

function isEmptyString(val) {
  return val === undefined || val === 'undefined' || typeof val === 'undefined' || val == null || val === 'null' || val.length <= 0
    ? true
    : false;
}

const companyOnboardingStep = () => {
  const isClaimer = StorageService.get('isClaimer');
  const isTrueSet = isClaimer === 'true';
  const clientUserRoleData = StorageService.get('clientUserRole');
  const clientUserRole = JSON.parse(clientUserRoleData);
  const userSessionPresent = StorageService.get('UserSessionPresent');
  if (userSessionPresent === 'false' || !userSessionPresent) {
    return clientOnboardingSteps.slice(1, 3);
  }
  if (clientUserRole?.name !== 'Billing & Admin' || !isTrueSet) {
    return clientOnboardingSteps.slice(0, 1);
  }
  return clientOnboardingSteps;
};

const TabSection = (props) => {
  const { pathname } = history.location;
  const { tabClassName } = props;
  const clientStep = companyOnboardingStep();
  const [onboardingSteps, setOnboardingSteps] = useState(() => {
    let temp;
    const clientOnboarded = StorageService.get('clientOnboarded') === true || StorageService.get('clientOnboarded') === 'true' || false;

    const registerType = StorageService.get('registerType');
    const userType = Number(StorageService.get('userType'));

    if (registerType === 'freelancer' && userType === 1 && !clientOnboarded) {
      temp = talentOnboardingSteps;
    } else if ((registerType === 'agency' && userType === 1) || (registerType === 'freelancer' && clientOnboarded)) {
      temp = agencyTalentOnboardingSteps;
    } else if (userType === 2) {
      temp = clientStep;
    } else if (userType === 3) {
      temp = agencyOnboardingSteps;
    } else if (userType === 5) {
      temp = recruiterOnboardingSteps;
    } else if (userType === 6) {
      temp = ambassadorOnboardingSteps;
    }
    return temp;
  });

  useEffect(() => {
    const userType = Number(StorageService.get('userType'));
    Emitter.on(Emitter.EVENTS.Progressbar_Val, (payload) => {
      const progressBarValue = payload.progressBarValue;
      let temp;
      if (userType === 2) {
        const clientCurrentStep = companyOnboardingStep();
        temp = clientCurrentStep.map((item) => ({
          ...item,
          progress: progressBarValue[item?.key]?.progress,
          isValid: progressBarValue[item?.key]?.status,
        }));
      } else {
        temp = onboardingSteps.map((item) => ({
          ...item,
          progress: progressBarValue[item?.key]?.progress,
          isValid: progressBarValue[item?.key]?.status,
        }));
      }
      setOnboardingSteps(temp);
    });

    return () => {
      Emitter.off(Emitter.EVENTS.Progressbar_Val);
    };
  }, [onboardingSteps]);

  const progressBar = (obj) => <ProgressMod value={obj.progress} max={100} className="sm ms-auto d-none d-md-block" />;

  return (
    <TabSectionBlock className={tabClassName}>
      <div className="inner-tab-block" data-testid="auth_layout">
        <ul>
          {(onboardingSteps || []).map((obj, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`obs_${index}`} className={pathname === obj.url ? 'active' : ''}>
              <LinkButtonMod
                className="link"
                onClick={(e) => {
                  handleBackButton(e, history, obj.url);
                }}
              >
                {obj.progress > 0 ? (
                  <SVG src={circleTickIcon} className="tick-icon progress-indicator" />
                ) : (
                  <span className="unfilled-circle progress-indicator" />
                )}
                <span className="position-relative">
                  <span className="me-2 d-none d-md-block">
                    {index + 1}. {obj.name}
                  </span>
                  {obj.isValid ? '' : <span className="danger-badge" />}
                </span>
                <SVG src={rightAngleIcon} className="right-angle" />
              </LinkButtonMod>
              {progressBar(obj)}
            </li>
          ))}
        </ul>
        <div className="d-block d-md-none text-center mt-3">
          {(onboardingSteps || [])
            .filter((i) => pathname === i.url)
            .map((obj, index) => (
              <React.Fragment key={`${index}_obs`}>
                <span className="mb-2 d-block">
                  {obj.step}. {obj.name}
                </span>
                {progressBar(obj)}
              </React.Fragment>
            ))}
        </div>
      </div>
    </TabSectionBlock>
  );
};

TabSection.defaultProps = {
  tabClassName: '',
  completeStepCount: 0,
};

TabSection.propTypes = {
  tabClassName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  completeStepCount: PropTypes.number,
};

export default TabSection;
