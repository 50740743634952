/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard component.
 */
import { defineMessages } from 'react-intl';
import StorageService from 'utils/StorageService';

export const scope = 'boilerplate.containers.Dashboard';

const changeEmailContent = `Dear ${StorageService.get(
  'firstName',
)}, you have been off boarded by your employer. You can retain your Notchup profile to continue finding great work, network with peers and participate in competitions by simply changing your registered email address here.`;

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Dashboard',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Dashboard',
  },
  buttonInvite: {
    id: `${scope}.buttonInvite`,
    defaultMessage: 'Send',
  },
  modelInvitePeopleHeader: {
    id: `${scope}.modelInvitePeopleHeader`,
    defaultMessage: 'Invite people',
  },
  labelInviteLink: {
    id: `${scope}.labelInviteLink`,
    defaultMessage: 'Invite link',
  },
  labelFullName: {
    id: `${scope}.labelFullName`,
    defaultMessage: 'Full name',
  },
  placeholderFullName: {
    id: `${scope}.placeholderFullName`,
    defaultMessage: 'Full name',
  },
  textOR: {
    id: `${scope}.textOR`,
    defaultMessage: 'or',
  },
  buttonCopyLink: {
    id: `${scope}.buttonCopyLink`,
    defaultMessage: 'Copy link',
  },
  buttonAddAnother: {
    id: `${scope}.buttonAddAnother`,
    defaultMessage: 'Add row',
  },
  copiedToClipBoard: {
    id: `${scope}.copiedToClipBoard`,
    defaultMessage: 'Invite link copied to your clipboard.',
  },
  AddTalent: {
    id: `${scope}.AddTalent`,
    defaultMessage: 'Add Talent',
  },
  placeHolderlabelRating: {
    id: `${scope}.placeHolderlabelRating`,
    defaultMessage: 'e.g. 100',
  },
  taskTitle: {
    id: `${scope}.taskTitle`,
    defaultMessage: 'Tasks',
  },
  activeProjectsTitle: {
    id: `${scope}.activeProjectsTitle`,
    defaultMessage: 'Active projects',
  },
  earningAndWithdrawTitle: {
    id: `${scope}.earningAndWithdrawTitle`,
    defaultMessage: 'Earning & Withdraw',
  },
  referAndEarnTitle: {
    id: `${scope}.referAndEarnTitle`,
    defaultMessage: 'Refer & Earn',
  },
  InviteMore: {
    id: `${scope}.InviteMore`,
    defaultMessage: 'Invite more talents like you',
  },
  comingSoon: {
    id: `${scope}.comingSoon`,
    defaultMessage: 'Coming soon',
  },
  agencyNoTalent: {
    id: `${scope}.agencyNoTalent`,
    defaultMessage: 'There is no talent associated with the agency account',
  },
  changeEmail: {
    id: `${scope}.changeEmail`,
    defaultMessage: 'change Email',
  },
  changeEmailContent: {
    id: `${scope}.changeEmailContent`,
    defaultMessage: changeEmailContent,
  },
  BtnChangeProfile: {
    id: `${scope}.BtnChangeProfile`,
    defaultMessage: 'Change Email',
  },
  activeProjects: {
    id: `${scope}.activeProjects`,
    defaultMessage: 'Active projects',
  },
  recommededJobs: {
    id: `${scope}.recommededJobs`,
    defaultMessage: 'Recommended jobs',
  },
  listContent1: {
    id: `${scope}.listContent1`,
    defaultMessage: 'Your own unique skills graph',
  },
  listContent2: {
    id: `${scope}.listContent2`,
    defaultMessage: 'Personalised job matching',
  },
  listContent3: {
    id: `${scope}.listContent3`,
    defaultMessage: 'Super-quick hiring processes',
  },
  profileInfoContent: {
    id: `${scope}.profileInfoContent`,
    defaultMessage:
      'Complete your profile and start working for some of the fastest growing tech companies and accelerate your career growth with:',
  },
  labelRole: {
    id: `${scope}.labelRole`,
    defaultMessage: 'Role',
  },
  labelCompany: {
    id: `${scope}.labelCompany`,
    defaultMessage: 'Company name',
  },
  yourTeams: {
    id: `${scope}.yourTeams`,
    defaultMessage: 'Your teams',
  },
  // empty team message
  emptyTeamTitle: {
    id: `${scope}.emptyTeamTitle`,
    defaultMessage: 'Get going, as a team',
  },
  emptyTeamText: {
    id: `${scope}.emptyTeamTitle`,
    defaultMessage: 'Forming a structured team is the perfect way to take on new projects alongside your closest friends and co-workers.',
  },
  buildTeamOption1: {
    id: `${scope}.buildTeamOption1`,
    defaultMessage: 'Showcase your collective talent to the world',
  },
  buildTeamOption2: {
    id: `${scope}.buildTeamOption2`,
    defaultMessage: 'Work on the projects your team was built for',
  },
  buildTeamOption3: {
    id: `${scope}.buildTeamOption3`,
    defaultMessage: 'Operate as a self-sufficient micro-agency  ',
  },
  createTeamBtn: {
    id: `${scope}.createTeamBtn`,
    defaultMessage: 'Create your team',
  },
  noMatchingJobsTitle: {
    id: `${scope}.noMatchingJobsTitle`,
    defaultMessage: 'No matching donuts!',
  },
  noMatchingJobsText: {
    id: `${scope}.noMatchingJobsText`,
    defaultMessage: 'Keeping your profile up to date will enable our algorithms to better match jobs with the your career motivations.',
  },
  updateProfileBtn: {
    id: `${scope}.updateProfileBtn`,
    defaultMessage: 'Open profile view',
  },
  viewJobsBtn: {
    id: `${scope}.viewJobsBtn`,
    defaultMessage: 'Open all the live jobs',
  },
  noTalent: {
    id: `${scope}.noTalent`,
    defaultMessage: 'You haven’t added any resource to your team yet! Add resources to see your team compositions.',
  },
  addResourcesBtn: {
    id: `${scope}.addResourcesBtn`,
    defaultMessage: 'Add your resources',
  },
  noEarning1: {
    id: `${scope}.noEarning1`,
    defaultMessage: 'Your talents are currently not working on any active project.',
  },
  noEarning2: {
    id: `${scope}.noEarning2`,
    defaultMessage: 'Please refer your talents to our open jobs and see your earning here.',
  },
  buildOption1: {
    id: `${scope}.buildOption1`,
    defaultMessage: 'Your own unique skills graph',
  },
  buildOption2: {
    id: `${scope}.buildOption2`,
    defaultMessage: 'Personalised job matching',
  },
  buildOption3: {
    id: `${scope}.buildOption3`,
    defaultMessage: 'Super-quick hiring processes',
  },
  completeProfileText: {
    id: `${scope}.completeProfileText`,
    defaultMessage: 'Complete your profile and start working on projects.',
  },
  noMatchingResourceText: {
    id: `${scope}.noMatchingResourceText`,
    defaultMessage:
      'Keeping your profile up to date will enable our algorithms to better match jobs with the your career motivations. Add all your talents onboard to get recommeded jobs for you.',
  },
  updateAgencyProfileBtn: {
    id: `${scope}.updateAgencyProfileBtn`,
    defaultMessage: 'Update your profile',
  },
  jobRecommendationTitle: {
    id: `${scope}.jobRecommendationTitle`,
    defaultMessage: 'Jobs recommended for you',
  },
  referTalentBtn: {
    id: `${scope}.referTalentBtn`,
    defaultMessage: 'Refer talents',
  },
  talentMatchedText: {
    id: `${scope}.talentMatchedText`,
    defaultMessage: 'Talents matched',
  },
  talentNotMatchedText: {
    id: `${scope}.talentNotMatchedText`,
    defaultMessage: 'No potential talent matched with this job. Add more resources in your team.',
  },
  addResourceJobBriefBtn: {
    id: `${scope}.addResourceJobBriefBtn`,
    defaultMessage: 'Add a resource',
  },
  emptyTalentScreenTitle: {
    id: `${scope}.emptyTalentScreenTitle`,
    defaultMessage: 'Get your talents onboard',
  },
  emptyTalentScreenText: {
    id: `${scope}.emptyTalentScreenText`,
    defaultMessage:
      'Invite all your talents to your account. This will help us to generate their unique skills graph and for you to better allocate them to open requirements.',
  },
  currentOpenJobsTitle: {
    id: `${scope}.currentOpenJobsTitle`,
    defaultMessage: 'Current open jobs',
  },
  addMoreResouceBtn: {
    id: `${scope}.addMoreResouceBtn`,
    defaultMessage: 'Add more resources',
  },
  profileCompleteMessage: {
    id: `${scope}.profileCompleteMessage`,
    defaultMessage: 'Take a few minutes to complete your profile, and get access to:',
  },
  agencyProfileOption1: {
    id: `${scope}.agencyProfileOption1`,
    defaultMessage: 'Skills graphs for each team member',
  },
  agencyProfileOption2: {
    id: `${scope}.agencyProfileOption2`,
    defaultMessage: 'Personalised resource matching',
  },
  agencyProfileOption3: {
    id: `${scope}.agencyProfileOption3`,
    defaultMessage: 'Fast-tracked resource allocation to open projects',
  },
  agencyCompleteProfileText: {
    id: `${scope}.agencyCompleteProfileText`,
    defaultMessage: 'Before referring your talents to the jobs, please complete your profile and add your all talents onboard.',
  },
  agencyCompleteProfileInfo: {
    id: `${scope}.agencyCompleteProfileInfo`,
    defaultMessage: 'Take a few minutes to complete your profile, and get access to:',
  },
  agencyProfileCompleteOption1: {
    id: `${scope}.agencyProfileCompleteOption1`,
    defaultMessage: 'Skills graphs for each team member',
  },
  agencyProfileCompleteOption3: {
    id: `${scope}.agencyProfileCompleteOption3`,
    defaultMessage: 'Fast-tracked job applications reply',
  },
});
