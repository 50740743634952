import React from 'react';
import { userExists, getUserRegisterType } from 'utils/Helper';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { P, ToastifyMessage } from 'components';
import { toast } from 'react-toastify';
import SwitchComponent from 'components/Switch';
import get from 'lodash/get';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavItem, NavLink, Nav, Dropdown, DropdownItem } from 'reactstrap';
import { defaultProps, propTypes } from 'containers/proptypes';
import LogoutLink from 'components/LogoutLink';
import SVG from 'react-inlinesvg';
import StorageService from 'utils/StorageService';
import Emitter from 'utils/emitter';
import {
  userIcon,
  logoutIcon,
  backArrowIcon,
  helpIcon,
  closeIcon,
  AGENCY,
  TALENT,
  settingIcon,
  API_URL,
  AVAILABILITY,
} from 'containers/App/constants';
import { handleBackToAdmin } from 'containers/Admin/ProxyLogin/utils';
import Notifications from 'containers/Notifications';
import { VALIDATION } from 'utils/constants';
import request from 'utils/request';
import { isEmpty } from 'lodash';

import {
  ProfileImgModal,
  UserImgModal,
  SecondaryNav,
  TalentHeaderContainer,
  ProfileNavBarCloseButton,
  ProfileNavModalContainer,
  ProfileTitle,
  UserProfileMenu,
  OpenToWorkCard,
  ProfileImg,
  UserImg,
  UserProfileToggle,
} from './header-style';
import messages from './messages';
import Logout from '../LogoutLink/Logout';
import { talentNavPages, agencyNavPages } from './constants';
import history from 'utils/history';

export class TalentHeader extends React.Component {
  constructor(props) {
    super(props);
    const apiSignupStep = Number(StorageService.get('apiSignupStep'));
    const profilePicture = StorageService.get('profilePicture');
    const agencyLogo = StorageService.get('agencyLogo');
    const path = history?.location?.pathname;
    this.state = {
      dropdownOpen: false,
      isSignIn: userExists(),
      roleType: getUserRegisterType(),
      apiSignupStep,
      profilePicture,
      agencyLogo,
      isProfileModelOpen: false,
      isOpenToWork: false,
      path,
    };
  }

  setConstructor = () => {
    const proxyType = StorageService.get('proxyType');
    const isSignupStep = StorageService.get('signupStep');
    const profilePicture = StorageService.get('profilePicture');
    const agencyLogo = StorageService.get('agencyLogo');
    const stateData = {
      dropdownOpen: false,
      isSignIn: userExists(),
      roleType: getUserRegisterType(),
      isSignupStep,
      profilePicture,
      agencyLogo,
      proxyType,
      badgeConfiguration: {},
      userDetails: {},
    };
    this.setState({ ...stateData });
  };

  componentDidMount() {
    this.setConstructor();
    this.callFetchAPI();

    Emitter.on('userTypeSelected', (userTypeSelected) => {
      if (userTypeSelected) {
        const profilePicture = StorageService.get('profilePicture');
        const agencyLogo = StorageService.get('agencyLogo');
        const roleType = getUserRegisterType();
        this.setState({
          roleType,
          profilePicture,
          agencyLogo,
        });
      }
    });
    Emitter.on('profilePicture', (profilePicture) => {
      let url = '';
      if (profilePicture) {
        url = `${profilePicture}?_t=${new Date().getTime()}`;
      }
      this.setState({
        profilePicture: url,
      });
      StorageService.set('profilePicture', url, { hash: true });
    });
    Emitter.on('agencyLogo', (agencyLogo) => {
      let url = '';
      if (agencyLogo) {
        url = `${agencyLogo}?_t=${new Date().getTime()}`;
      }
      this.setState({
        agencyLogo: url,
      });
      StorageService.set('agencyLogo', url, { hash: true });
    });

    Emitter.on('proxyLoginAgency', (proxyLoginAgency) => {
      if (proxyLoginAgency) {
        this.setConstructor();
      }
    });

    Emitter.on('badgeConfigurationUpdated', (badgeConfigurationUpdated) => {
      this.setState({ badgeConfiguration: badgeConfigurationUpdated });
    });
    Emitter.on('updateUserAvailability', () => {
      this.callFetchAPI();
    });
  }

  componentWillUnmount() {
    Emitter.off('userTypeSelected');
    Emitter.off('profilePicture');
    Emitter.off('agencyLogo');
    Emitter.off('proxyLoginAgency');
    Emitter.off('badgeConfigurationUpdated');
    Emitter.off('updateUserAvailability');
  }

  /**
   * call dropdownToggle on tab change
   * @author Innovify
   */
  dropdownToggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  closeModalHandler = () => {
    this.setState({ isProfileModelOpen: false });
  };

  openModalHandler = () => {
    this.setState({ isProfileModelOpen: true });
  };

  callFetchAPI = () => {
    const data = {
      method: 'GET',
    };
    const requestURL = `${API_URL}${TALENT}${AVAILABILITY}`;
    request(requestURL, data)
      .then((response) => {
        if (get(response, 'status')) {
          this.setState({ userDetails: get(response, 'data', {}), isOpenToWork: get(response, 'data.availability', false) });
        }
      })
      .catch(() => {});
  };

  onChangeAvailability = (state) => {
    const requestURL = `${API_URL}${TALENT}${AVAILABILITY}`;

    const apiCallData = {
      method: 'PUT',
      body: { availability: state },
    };

    request(requestURL, apiCallData)
      .then(this.callFetchAPI)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  handleSwitch = () => {
    const { isOpenToWork } = this.state;
    this.setState({ isOpenToWork: !isOpenToWork });
    this.onChangeAvailability(!isOpenToWork);
  };

  userNameGenerator = () => {
    const firstName = StorageService.get('firstName');
    const lastName = StorageService.get('lastName');
    const userFirstName = firstName || '';
    const userLastName = lastName || '';
    const userNameStr = `${userFirstName} ${userLastName}`;
    return isEmpty(userNameStr.trim()) ? 'User' : userNameStr;
  };

  renderUserImage = (roleType) => {
    const { profilePicture, agencyLogo } = this.state;

    if (profilePicture || agencyLogo) {
      return (
        <div className="d-flex align-items-center">
          <ProfileImgModal className="me-3">
            {(roleType === 'talent' || roleType === 'talent_agency') && profilePicture && (
              <UserImgModal src={profilePicture} alt="user-profile" />
            )}
            {roleType === 'agency' && agencyLogo && <UserImgModal src={agencyLogo} alt="user-profile" />}
          </ProfileImgModal>
          <ProfileTitle>{this.userNameGenerator()}</ProfileTitle>
        </div>
      );
    }
    return (
      <div className="d-flex align-items-center">
        <ProfileImgModal className="me-3">
          <SVG src={userIcon} />
        </ProfileImgModal>
        <ProfileTitle>{this.userNameGenerator()}</ProfileTitle>
      </div>
    );
  };

  render() {
    const {
      isSignIn,
      roleType,
      proxyType,
      badgeConfiguration,
      apiSignupStep,
      isProfileModelOpen,
      location,
      isOpenToWork,
      dropdownOpen,
      profilePicture,
      agencyLogo,
      userDetails,
      path,
    } = this.state;

    const { windowSize } = this.props;
    const userVersion = StorageService.get('userVersion');
    const profileId = StorageService.get('profileId');
    const clientOnboardedTalent = StorageService.get('clientOnboarded') === 'true';
    let talentOnBoard = false;
    let talentUser = false;
    let agencyUser = false;
    let agencyOnBoard = false;
    const pathName = get(location, 'pathname');

    if (roleType === 'talent') {
      talentUser = true;
      talentOnBoard = (apiSignupStep >= 6 && userVersion !== 'v2') || (apiSignupStep === 7 && userVersion === 'v2');
    }
    if (roleType === 'talent_agency') {
      talentUser = true;
      talentOnBoard = (apiSignupStep >= 5 && userVersion !== 'v2') || (apiSignupStep === 5 && userVersion === 'v2');
    }
    if (roleType === 'agency') {
      agencyUser = true;
      agencyOnBoard = apiSignupStep === 8;
    }

    return (
      <>
        {isSignIn && (talentUser || agencyUser) && (
          <TalentHeaderContainer className="d-flex align-items-center" data-testid="TalentHeaderContainer">
            <SecondaryNav>
              <Link to="/dashboard" className="nav-link d-none">
                <SVG src={helpIcon} />
              </Link>

              <>
                {windowSize > 768 &&
                  get(userDetails, 'registerType', '') === 'freelancer' &&
                  !clientOnboardedTalent &&
                  !path?.includes(profileId) && (
                    <OpenToWorkCard className="d-flex bg-secondary-gradient mt-0 dense">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <P className="p14 mb-0 pe-2">
                          <FormattedMessage {...messages.openToWorkText} />
                        </P>
                        <SwitchComponent checked={isOpenToWork} showLabel={false} onChange={() => this.handleSwitch()} />
                      </div>
                    </OpenToWorkCard>
                  )}
                {(talentOnBoard || agencyOnBoard) && <Notifications newNotification={get(badgeConfiguration, 'newNotification')} />}
              </>
            </SecondaryNav>

            {windowSize <= 768 ? (
              <ProfileImgModal width="30px" height="30px" bgColor onClick={() => this.openModalHandler()}>
                {talentUser && profilePicture && <UserImgModal src={profilePicture} alt="user-profile" />}
                {agencyUser && agencyLogo && <UserImgModal src={agencyLogo} alt="agency-profile" />}
                {(talentUser || agencyUser) && !profilePicture && !agencyLogo && (
                  <ProfileImg>
                    <SVG src={userIcon} />
                  </ProfileImg>
                )}
              </ProfileImgModal>
            ) : (
              <Dropdown isOpen={dropdownOpen} toggle={this.dropdownToggle}>
                <UserProfileToggle>
                  {talentUser && profilePicture && <UserImg src={profilePicture} alt="user-profile" />}
                  {talentUser && !profilePicture && (
                    <ProfileImg>
                      <SVG src={userIcon} />
                    </ProfileImg>
                  )}
                  {agencyUser && agencyLogo && <UserImg src={agencyLogo} alt="user-profile" />}
                  {agencyUser && !agencyLogo && (
                    <ProfileImg>
                      <SVG src={userIcon} />
                    </ProfileImg>
                  )}
                </UserProfileToggle>
                <UserProfileMenu right>
                  <div className="inner-block">
                    {talentUser && (
                      <DropdownItem tag={Link} to={`${TALENT}/${profileId}`}>
                        <SVG src={userIcon} />
                        <FormattedMessage {...messages.menuItemMyProfile} />
                      </DropdownItem>
                    )}

                    {agencyUser && (
                      <DropdownItem tag={Link} to={`${AGENCY}/about-you`}>
                        <SVG src={userIcon} />
                        <FormattedMessage {...messages.menuItemMyProfile} />
                      </DropdownItem>
                    )}

                    {talentUser && (
                      <DropdownItem tag={Link} to={`${TALENT}/account-settings`}>
                        <SVG src={settingIcon} />
                        <FormattedMessage {...messages.menuItemAccountSetting} />
                      </DropdownItem>
                    )}

                    {agencyUser && (
                      <DropdownItem tag={Link} to={`${AGENCY}/agency-account-settings`}>
                        <SVG src={settingIcon} />
                        <FormattedMessage {...messages.menuItemAccountSetting} />
                      </DropdownItem>
                    )}
                    <DropdownItem tag={LogoutLink}>
                      <SVG src={logoutIcon} />
                      <FormattedMessage {...messages.menuItemLogout} />
                    </DropdownItem>

                    {/* proxyLogin Return to admin */}
                    {proxyType && (
                      <DropdownItem onClick={handleBackToAdmin}>
                        <SVG src={backArrowIcon} />
                        <FormattedMessage {...messages.menuItemBackToAdmin} />
                      </DropdownItem>
                    )}
                  </div>
                </UserProfileMenu>
              </Dropdown>
            )}
          </TalentHeaderContainer>
        )}
        <ReactModal
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          ariaModal
          isOpen={isProfileModelOpen}
          contentLabel="Inline Styles Modal Profile"
          style={{
            content: {
              borderRadius: '0px',
              background: '#fff',
              overflow: 'auto',
              border: 'none',
              inset: '0px',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              padding: '30px',
            },
          }}
        >
          <div>
            <div className="d-flex justify-content-end mb-2">
              <ProfileNavBarCloseButton type="button" onClick={() => this.closeModalHandler()}>
                <img src={closeIcon} width="12.5" height="12.5" alt="close" />
                <span className="visually-hidden">Close</span>
              </ProfileNavBarCloseButton>
            </div>
            <PerfectScrollbar>
              {this.renderUserImage(roleType)}

              {get(userDetails, 'registerType', '') === 'freelancer' && !clientOnboardedTalent && !path?.includes(profileId) && (
                <OpenToWorkCard className="d-flex bg-secondary-gradient">
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <P className="p14 mb-0">
                      <FormattedMessage {...messages.openToWorkText} />
                    </P>
                    <SwitchComponent checked={isOpenToWork} showLabel={false} onChange={() => this.handleSwitch()} />
                  </div>
                </OpenToWorkCard>
              )}

              <ProfileNavModalContainer>
                <Nav>
                  {(talentUser || talentOnBoard) &&
                    talentNavPages.map((navItem) => (
                      <NavItem key={navItem.key}>
                        <NavLink
                          to={navItem.key === 'my_profile' ? `${navItem.pathName}/${profileId}` : navItem.pathName}
                          className={navItem.paths.some((i) => i === pathName) ? 'active' : ''}
                          tag={Link}
                          title={navItem.title}
                        >
                          <span className="icon">
                            <SVG src={navItem.icon} />
                          </span>
                          <span className="menu-option">{navItem.title}</span>
                        </NavLink>
                      </NavItem>
                    ))}

                  {(agencyUser || agencyOnBoard) &&
                    agencyNavPages.map((navItem) => (
                      <NavItem key={navItem.key}>
                        <NavLink
                          to={navItem.pathName}
                          className={navItem.paths.some((i) => i === pathName) ? 'active' : ''}
                          tag={Link}
                          title={navItem.title}
                        >
                          <span className="icon">
                            <SVG src={navItem.icon} />
                          </span>
                          <span className="menu-option">{navItem.title}</span>
                        </NavLink>
                      </NavItem>
                    ))}

                  {/* Logout link */}
                  <NavItem>
                    <NavLink>
                      <Logout>
                        <div className="d-flex align-items-center">
                          <span className="icon">
                            <SVG src={logoutIcon} />
                          </span>
                          <span className="menu-option">
                            <FormattedMessage {...messages.menuItemLogout} />
                          </span>
                        </div>
                      </Logout>
                    </NavLink>
                  </NavItem>

                  {/* proxyLogin Return to admin */}
                  {proxyType && (
                    <NavItem>
                      <NavLink onClick={() => handleBackToAdmin()}>
                        <span className="icon">
                          <SVG src={backArrowIcon} />
                        </span>
                        <span className="menu-option">
                          <FormattedMessage {...messages.menuItemBackToAdmin} />
                        </span>
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              </ProfileNavModalContainer>
            </PerfectScrollbar>
          </div>
        </ReactModal>
      </>
    );
  }
}

TalentHeader.defaultProps = defaultProps;
TalentHeader.propTypes = propTypes;

export default TalentHeader;
