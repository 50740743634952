import history from 'utils/history';
import StorageService from 'utils/StorageService';
import Emitter from 'utils/emitter';

export const handleBackToAdmin = () => {
  StorageService.delete('firstName');
  // StorageService.delete('userEmail');
  StorageService.delete('userType');
  StorageService.delete('restrictedActions');
  StorageService.delete('registerType');
  StorageService.delete('clientBrand');
  StorageService.delete('signupStep');
  StorageService.delete('agencyLogo');
  StorageService.delete('profilePicture');
  StorageService.delete('isPaymentSkipped');
  StorageService.delete('clientToken');
  StorageService.delete('recruiterToken');
  StorageService.delete('agencyToken');
  StorageService.delete('talentToken');
  StorageService.delete('proxyType');
  StorageService.delete('proxyToken');
  StorageService.delete('profileId');
  StorageService.delete('userId');
  StorageService.delete('ClientTimeZone');
  StorageService.delete('clientProjectPageNumber');
  StorageService.delete('clientProjectStatus');
  StorageService.delete('clientProjectSort');
  StorageService.delete('clientInterviewRequestsPageNumber');
  StorageService.delete('adminInterviewRequestsPageNumber');
  StorageService.delete('clientInterviewRequestsFilterObject');
  StorageService.delete('adminInterviewRequestsFilterObject');
  StorageService.delete('clientInterviewRequestsSearch');
  StorageService.delete('adminInterviewRequestsSearch');
  StorageService.delete('talentInterviewRequestsPageNumber');
  StorageService.delete('talentInterviewRequestsFilterObject');
  StorageService.delete('talentInterviewRequestsSearch');
  StorageService.delete('adminClientSearch');
  StorageService.delete('clientsPageNumber');
  StorageService.delete('clientsFilterObject');
  StorageService.delete('clientOnboarded');
  StorageService.set('userType', 4, { hash: true });
  Emitter.emit(Emitter.EVENTS.HAMBURGER_TOGGLE, false);
  Emitter.emit('proxyBackToAdmin', true);
  history.replace('/admin/');
};
